import React from "react"
import Helmet from "react-helmet"
import Banner from "../components/Banner"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Form from "../components/Form"

const config = {
  title: 'adrian.bright contact form',
  successMessage: 'Thank you for contacting me.',
  errorMessage: 'Nope its broke - please email me instead: adman020@gmail.com',
  fields:{
    firstName: '',
    email: '',
    msg: ''
  },
  fieldsConfig:  [
   { id: 1, label: 'Name', fieldName: 'firstName', type: 'text',placeholder:'Your name', isRequired: true , className:'first-name-field'},
   { id: 2, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your email', isRequired: true , className:'email-field'},
   { id: 3, label: 'How can I help?', fieldName: 'msg', type: 'textarea',placeholder:'Write something...', isRequired: true , className:'message-field'}
  ]
}

export default function ContactPage() {
  return (
    
    <><Helmet
    htmlAttributes={{
      lang: 'en',
    }}>
        <meta charSet="utf-8" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,400;1,500&display=swap"
          rel="stylesheet"
        />
        <link rel="canonical" href="https://www.adrianbright.co.uk" />
        <title>Adrian Bright Front End Developer</title>
        <description>Adrian Bright Front End Developer Contact me</description>
      </Helmet>
      <Banner />
      <Header />

      <Form config={config} />
    
      <Footer />
    </>
  )
}