import React from "react"
import PropTypes from "prop-types";
import axios from "axios";
import Container from "./Container"

import "../styles/scss/form.scss"


class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSent: false,
      error: null
    };
  }


  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: "post",
      url: 'https://www.adrianbright.co.uk/api/contact/index.php',
      headers: { 
        "content-type": "application/json"
       },
      data: this.state
    })
      .then(result => {
        if (result.data.sent) {
          this.setState({
            mailSent: result.data.sent
          });
          this.setState({ error: false });
        } else {
          this.setState({ error: true });
        }
      })
      .catch(error => this.setState({ error: error.message }));
  };

  handleChange = (e, field) => {
    let value = e.target.value;
    let updateValue = {};
    updateValue[field] = value;
    this.setState(updateValue);
  };

  render() {
    const { successMessage, errorMessage, fieldsConfig } = this.props.config;
    return (

      <Container>
        <h1>Contact me</h1>
        <p>If you'd like to chat about a project please fill in the form below and I’ll get back to you.</p>
        
        <div className="row">
            <form action="#">
            {fieldsConfig &&
              fieldsConfig.map(field => {
                return (
                  <React.Fragment key={field.id}>
                    {field.type !== "textarea" ? ( 
                      <React.Fragment>
                        <div className="form-group">
                          <label className="form-label col-12 col-md-4">{field.label}</label>
                          <input
                            type={field.type}
                            className={field.klassName + ' form-control form-text col-12 col-md-8'}
                            placeholder={field.placeholder}
                            value={field.name}
                            onChange={e => this.handleChange(e, field.fieldName)}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="form-group">
                          <label className="form-label col-12 col-md-4">{field.label}</label>
                          <textarea className={field.klassName + ' form-control form-text col-12 col-md-8'} placeholder={field.placeholder} onChange={e => this.handleChange(e, field.fieldName)} value={field.name} />
                        </div>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}

              <div className="col-12 offset-lg-4">
                <input type="submit" className="btn" onClick={e => this.handleFormSubmit(e)} value="Send message" />
              </div>
        
              <div>
                {this.state.mailSent && <div className="success">{successMessage}</div>}
                {this.state.error && <div className="error">{errorMessage}</div>}
              </div>

          </form>
        </div>
       
      </Container>
    );
  }
}

export default Form;
//propTypes for the component
Form.propTypes = {
  config: PropTypes.object.isRequired
};